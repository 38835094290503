/* General Styles */
html, body {
  margin: 0;
  align-items: center;
  padding: 0;
  font-family: sans-serif;
  background: #fdf2f4;
}

/* App Wrapper with fixed width and height */
.App {
  position: sticky;
  max-width: 930px;
  margin: 0 auto;
  padding: 90px;
  margin-bottom: 250px;
}


/* Change the background and text color when text is selected */
::selection {
  background-color: #fbb2c1; /* Background color when selected */
  color: #ffffff; /* Text color when selected */
}

/* Change the selection color for text inside specific elements (e.g., .custom-header) */
.custom-header::selection {
  background-color: #ffcc00; /* Example custom background color */
  color: #000000; /* Example custom text color */
}

/* Section Separator */
.section {
  padding-top: 2em;
}

/* Header Section */
.header {
  font-size: 2em; /* Increase the font size */
  font-weight: bold; /* Optional: Make the text bold */
  line-height: 1; /* Adjust line height */
  width: 100%;
  margin: 0 0 20px 15px; /* Remove top margin, only bottom margin remains */
  margin-left: 90px;
}

.page-title {
  font-size: 2em; /* Increase the font size */
  font-weight: bold; /* Optional: Make the text bold */
  line-height: 1; /* Adjust line height */
}

/* Main Content Layout */
.main-content {
  justify-content: space-between;
  margin-top: 2em;
  width: 100%;
}

/* Profile Section */
.profile {
  display: flex;
  align-items: flex-start; /* Align items to the top */
  margin-bottom: 2em;
}

.profile .image {
  flex: 0 0 40%; /* Increase image width to 40% */
  max-width: 40%; /* Allow the image to take up more space */
  margin-right: 50px;
  margin-left: -5px;
  border-radius: 30%; /* Adjust the circular shape */
  overflow: hidden;
}

.profile .image img {
  width: 100%;
  height: auto; /* Maintain aspect ratio to prevent cropping */
  display: block; /* Ensures there are no extra gaps below the image */
  object-fit: cover; /* Ensure the image covers the container without stretching */
}

.profile .column {
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.profile .title {
  margin-bottom: 20px; /* Space between title and other profile content */
}

.profile .details {
  margin-bottom: 20px; /* Space between profile details and other content */
}

/* General Code-Like Styling */
.code-block {
  font-family: 'Arial', sans-serif; /* Code-like font */
  padding: 5px 8px; /* Padding around the text */
  border-radius: 4px; /* Rounded corners */
  font-size: 0.9em; /* Slightly smaller font size */
  display: inline-block; /* Ensure each block behaves like a code snippet */
  margin-right: 0; /* Small margin between code blocks */
  font-weight: bold; /* Make bold */
}

/* Specific Colors for Each Term */
.cloud-engineering {
  background-color: #ffebcc; /* Light orange background */
  color: #d35400; /* Dark orange text */
}

.solutions-architect {
  background-color: #c6f0d2; /* Light red background */
  color: #037b29; /* Dark red text */
}

.aws {
  background-color: #d4e6f1; /* Light blue background */
  color: #2980b9; /* Dark blue text */
}

.gcp {
  background-color: #e0f7fa; /* Light cyan background */
  color: #00796b; /* Dark cyan text */
}

.devops {
  background-color: #fed4f2; /* Light cyan background */
  color: #df30c5; /* Dark cyan text */
}

/* Technical Research */
.technical-research {
  width: 100%; /* Adjust width for two-column layout */
  margin-top: 2em;
}

.link-to-blog a {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits the color of the text */
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition for hover effect */
  display: inline-block;
  padding: 6px 10px;
  border-radius: 30px;
  margin-left: -45px;
  text-decoration: none;
  color: #000;
  font-weight: bold; /* Make links bold */
  border: 2px solid #fcb6c4; /* Add border to links */
}

.link-to-blog a:hover {
  color: #ffffff; /* Change text color on hover (light pink) */
  background-color: #fcb6c4; /* Optional: Add background color on hover */
  border-radius: 30px; /* Optional: Round the corners on hover */
  padding: 6px 10px; /* Optional: Add padding to make the hover effect more visible */
}

/* Contact */
.contact {
  width: 100%; /* Adjust width for two-column layout */
  margin-top: 2em;
}

.link-to-sns {
  display: inline-block; /* Makes each figure display inline */
  margin: 0; /* Removes default margin around figure */
  padding: 0; /* Removes default padding around figure */
  margin-left: -5px;
  margin-right: 5px;
}

.link-to-sns strong {
  display: inline; /* Ensures the strong tag inside the figure is inline */
}

.link-to-sns a {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits the color of the text */
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition for hover effect */
  display: inline-block;
  padding: 5px 11px;
  border-radius: 30px;
  text-decoration: none;
  color: #000;
  font-weight: bold; /* Make links bold */
  border: 2px solid #fcb6c4; /* Add border to links */
}

.link-to-sns a:hover {
  color: #ffffff; /* Change text color on hover (light pink) */
  background-color: #fcb6c4; /* Optional: Add background color on hover */
  border-radius: 30px; /* Optional: Round the corners on hover */
  padding: 5px 11px; /* Optional: Add padding to make the hover effect more visible */
}

p {
  display: flex; /* Use flexbox to align items in one line */
  align-items: center; /* Center align the content vertically */
  gap: 5px; /* Adds space between the items */
  margin-bottom: 10px;
}


/* Projects and Work Experience Columns */
.projects, .work-experience {
  width: 48%; /* Each column takes up 48% of the width */
  margin-bottom: 2em;
  margin-left: 10px;
}

.projects {
  float: left; /* Left-aligned column */
}

.work-experience {
  float: right; /* Right-aligned column */
}

.work-experience-item {
  margin-bottom: 20px;
}

.work-experience-details {
  color: #000000;
  margin-bottom: 0px;
}

.work-experience .work-experience-details p:first-of-type {
  margin-bottom: -5px; /* Shrink the margin between specific lines */
}

/* Adjust line spacing for activity details */
.work-experience-details p, 
.work-experience-details li {
  line-height: 1.5; /* Adjust line-height for better readability */
}


.link-to-article {
  margin-left: 0; /* Ensure the link aligns to the left */
  margin-top: 5px;
  margin-bottom: 0px;
}

.link-to-article a {
  color: #fda1c3; /* Simple blue color */
  text-decoration: none; /* Remove underline by default */
  font-weight: 500; /* Make the link bold */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.link-to-article a:hover {
  color: #f77791; /* Change color on hover */
  text-decoration: underline; /* Add underline on hover */
}

/* Skill Blocks Container */
.skills {
  display: flex; /* Align skill blocks horizontally */
  flex-wrap: wrap; /* Allow skill blocks to wrap to the next line if needed */
  gap: 10px; /* Space between skill blocks */
  margin-top: 10px; /* Space above the skill blocks */
}

/* Individual Skill Block */
.skill-block {
  background-color: #fdf2f4; /* Light background color */
  color: #333; /* Text color */
  padding: 4px 9px; /* Padding inside the block */
  border: 2px solid #f9bac6; /* Border around the block */
  border-radius: 9px; /* Rounded corners */
  font-size: 0.9em; /* Slightly smaller font size */
  font-weight: 500; /* Medium weight for text */
}

/* Leadership Section */
.leadership {
  clear: both; /* Clear floats above */
  margin-top: 2em;
  padding-top: 1em;
  margin-left: 10px;
}

.leadership h1 {
  color: #000000; /* Dark color for the heading */
  text-align: left; /* Center the main heading */
  font-size: 1.8em; /* Slightly larger font size */
  margin-bottom: 1em; /* Space below the heading */
}

.leadership h3 {
  margin-bottom: 0.3em; /* Reduced margin for subheadings */
  color: #000000; /* Highlight the activity title with pink */
}

/* Additional styling for specific text */
.sub-highlight-text {
  font-weight: 600; /* Medium weight between normal (400) and bold (700) */
  color: #555; /* Slightly darker than the normal text for emphasis */
  margin-top: -5px;
  margin-bottom: 5px;
}

.leadership p {
  margin-bottom: 8px; /* Standard margin for paragraphs */
}

.leadership .activity {
  border-left: 4px solid #f77791; /* Simple border on the left */
  padding-left: 10px; /* Padding inside the activity box */
  margin-bottom: 25px; /* Space between activities */
}

/* Styling for the activity period */
.activity-period {
  font-size: 0.9em; /* Smaller font size */
  color: #707070; /* Black color */
  margin-left: 10px; /* Space between title and period */
  font-weight: normal; /* Normal font weight */
}


.leadership .activity-details p:first-of-type {
  margin-bottom: -10px; /* Shrink the margin between specific lines */
}

/* Adjust line spacing for activity details */
.activity-details p, 
.activity-details li {
  line-height: 1.5; /* Adjust line-height for better readability */
}

.leadership .activity strong {
  font-weight: bold; /* Emphasize the strong text */
  font-size: 1.1em; /* Slightly larger font size */
}


/* Education Section */
.education {
  margin-left: 10px;
}

.educationitem ul li {
  margin-bottom: 10px; /* Adjust the value as needed */
  margin-left: -10px;
}

/* Certificate Section */
.certificate {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.certificate-item {
  display: flex;
  align-items: center; /* Aligns items vertically */
  margin-bottom: 10px;
}

.certificate-text {
  flex: 1; /* Allows the text to take available space */
}

.certificate-image {
  flex: 0 0 150px; /* Fixes the width of the image container */
  max-width: 100px; /* Maximum width for the image */
  margin-right: 15px; /* Spacing between text and image */
}

.certificate-image img {
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains the aspect ratio */
}


/* Langage Section */
.languages {
  margin-left: 10px;
}

.languageitem ul li {
  margin-bottom: 10px; /* Adjust the value as needed */
  margin-left: -10px;
}

/* Hover Effects */
.link-to-page a {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  color: #000;
  font-weight: bold; /* Make links bold */
  border: 2px solid #fcb6c4; /* Add border to links */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.link-to-page a:hover {
  background-color: #fcb6c4; /* Light pink color */
  color: #fff;
  margin-left: -55px; /* Remove left margin */
}

/* Projects Styles */

.projects a {
  text-decoration: none;
  color: inherit;
  font-weight: bold; /* Make links bold */
  border: 2px solid #fcb6c4; /* Add border to project links */
  padding: 8px 17px; /* Add padding to fit the border */
  display: inline-block;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: -45px; /* Remove left margin */
}

.projects a:hover {
  background-color: #fcb6c4; /* Light pink color */
  color: #fff;
}

/* Section Titles */
h2, h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

/* Blog Page Styles */
.blog-page {
  padding: 2em;
  background-color: #fdf2f4; /* Matching the existing background */
  color: #333;
}

.blog-page h1 {
  text-align: center;
  margin-bottom: 1.5em;
  font-size: 2.2em;
  color: #4a4a4a;
  font-weight: bold;
}

/* Post Card Styling */
.posts-container {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.post-card {
  background: #ffffff;
  padding: 1.5em;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.post-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.post-card h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #f77791; /* Matching the pink highlight color */
}

.post-card .post-date {
  color: #888;
  font-size: 0.9em;
  margin-bottom: 0.5em;
}

/* Tag Styling */
.post-tags {
  margin: 0.5em 0;
}

.tag {
  display: inline-block;
  background-color: #f9bac6; /* Light pink background */
  color: #333; /* Darker text */
  padding: 0.3em 0.8em;
  margin-right: 0.5em;
  border-radius: 15px;
  font-size: 0.85em;
}

/* Excerpt */
.post-excerpt {
  margin-top: 0.5em;
  color: #555;
}

/* Link Styling */
.post-card a {
  color: #f77791; /* Matching the pink highlight color */
  text-decoration: none;
  transition: color 0.3s ease;
}

.post-card a:hover {
  color: #d3545d; /* Slightly darker pink on hover */
}
